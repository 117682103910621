<template>
  <div class="d-flex align-center">
    <v-avatar class="hidden-sm-and-down mr-3" size="32">
      <img
        v-if="url !== null && !urlIsBroken"
        :alt="fullName"
        :src="url"
        @error="urlIsBroken = true"
      />
      <v-icon v-else large>mdi-account-circle</v-icon>
    </v-avatar>
    <div>
      {{ fullName }}
    </div>
  </div>
</template>

<script>
export default {
  name: "UserNameAndAvatar",

  props: {
    fullName: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    urlIsBroken: false,
  }),
}
</script>

<style lang="scss" scoped></style>
