<template>
  <div>
    <v-row align="center" justify="space-between" no-gutters>
      <v-col>
        <GoBack />
        <p class="header-text">
          {{ classData.class_id.name }}
        </p>
        <p class="lightCarbon--text font-weight-regular caption">
          {{ classData.courses.join(", ") }}
        </p>
        <!-- <p class="lightCarbon--text font-weight-regular caption">
          {{ batch.name }}
        </p> -->
        <p class="lightCarbon--text font-weight-regular caption">
          {{ moment(classData.start_time).format("LLL") }} -
          {{ moment(classData.end_time).format("LLL") }}
        </p>
      </v-col>
      <v-col :class="{ 'mt-5': $vuetify.breakpoint.mdAndDown }" cols="auto">
        <v-tooltip bottom :disabled="joinUrl" :open-delay="0">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn
                class="px-14"
                color="primary"
                depressed
                :disabled="!joinUrl"
                :href="joinUrl"
                min-height="55"
                min-width="60"
                target="_blank"
              >
                Join Class
              </v-btn>
            </span>
          </template>
          <span class="caption">
            Joining link is not available. Try refreshing this page if the class
            has begun.
          </span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-divider class="mt-2 mb-4" />

    <v-data-table
      :custom-sort="() => students.data"
      :headers="headers"
      hide-default-footer
      item-key="uuid"
      :items="students.data"
      :loading="listLoading"
      multi-sort
      :sort-by="sort.fields"
      :sort-desc="sort.desc"
      @update:options="handleSort"
    >
      <template v-slot:[`header.action`]>
        <span class="subtitle-2 lightCarbon--text mr-4">
          {{ totalEntries }}
        </span>
      </template>

      <template v-slot:[`item.user`]="{ item }">
        <UserNameAndAvatar
          :full-name="`${item.user.first_name} ${item.user.last_name}`"
          :url="item.user.photo"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div v-if="item.status === 'present'">
          <v-chip color="success" label outlined small>
            Present
          </v-chip>
        </div>
        <div v-else-if="item.status === 'absent'">
          <v-chip color="error" label outlined small>
            Absent
          </v-chip>
        </div>
        <!-- v-else-if="item.status === 'yet_to_start'" -->
        <div v-else>
          <v-btn
            class="green--text"
            :disabled="item.status === 'present'"
            :loading="presentBtnIndexes.indexOf(item.uuid) !== -1"
            small
            text
            @click="markAttendance(item.uuid, 'present')"
          >
            Mark Present
          </v-btn>
          <v-btn
            class="red--text pr-0 pr-md-2"
            :disabled="item.status === 'absent'"
            :loading="absentBtnIndexes.indexOf(item.uuid) !== -1"
            small
            text
            @click="markAttendance(item.uuid, 'absent')"
          >
            Mark Absent
          </v-btn>
        </div>
      </template>
      <template v-slot:footer>
        <Paginator
          :page="page"
          :total="students.total"
          @change="handlePagination"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import GoBack from "@/components/shared/GoBack"
import Paginator from "@/components/shared/Paginator"
import UserNameAndAvatar from "@/components/shared/UserNameAndAvatar"

import urls from "@/utils/urls"
import { getValueOrNull, getSortableFieldsArray } from "@/utils/helpers"

export default {
  components: {
    Paginator,
    GoBack,
    UserNameAndAvatar,
  },

  data: () => ({
    joinUrl: null,

    classData: {
      class_id: {
        name: "Class",
      },
      courses: [],
      id: -1,
      start_time: new Date(),
      end_time: new Date(),
    },

    // batch: {
    //   name: "Batch",
    // },

    students: {
      data: [],
      total: 0,
    },

    /** Loaders */
    listLoading: false,
    sendInvitesLoading: false,
    loadingCreateClass: false,

    absentBtnIndexes: [],
    presentBtnIndexes: [],

    /** Table Headers */
    page: 1,
    sort: { fields: [], desc: [] },
    headers: [
      {
        text: "Name",
        value: "user",
      },
      { text: "Email address", value: "user.email", sortable: false },
      { text: "", value: "action", sortable: false, align: "end" },
    ],
  }),

  computed: {
    totalEntries() {
      return `${
        this.page * 10 > this.students.total
          ? this.students.total
          : this.page * 10
      } of ${this.students.total} Entries`
    },
  },

  beforeMount() {
    this.classData.id = this.$route.params.classId
  },

  methods: {
    async getStudentData() {
      this.listLoading = true
      try {
        const res = await this.$http.get(
          urls.classes.getClassStudents(this.classData.id),
          {
            params: {
              page: this.page,
              sort: getSortableFieldsArray(this.sort.fields, this.sort.desc),
            },
          }
        )
        const data = res.data.data

        this.students = {
          data: data.attendance_status,
          total: res.data.total,
        }
        this.classData = data.class_schedule

        this.joinUrl = data.class_schedule.video_meeting?.meeting_url
      } catch (error) {
        console.error(error)
      } finally {
        this.listLoading = false
      }
    },

    async markAttendance(attendanceId, status) {
      if (status === "absent") {
        this.absentBtnIndexes.push(attendanceId)
      } else if (status === "present") {
        this.presentBtnIndexes.push(attendanceId)
      }
      try {
        await this.$http.put(urls.classes.markAttendance(attendanceId), {
          status: getValueOrNull(status),
        })

        this.students.data = this.students.data.map(obj => {
          if (obj.uuid === attendanceId) {
            obj.status = status
          }
          return obj
        })
      } catch (error) {
        console.error(error)
      } finally {
        if (status === "absent") {
          this.absentBtnIndexes = this.absentBtnIndexes.filter(
            id => id !== attendanceId
          )
        } else if (status === "present") {
          this.presentBtnIndexes = this.presentBtnIndexes.filter(
            id => id !== attendanceId
          )
        }
      }
    },

    handleSort({ sortBy, sortDesc }) {
      this.resetPage()
      this.sort = {
        fields: sortBy,
        desc: sortDesc,
      }
      this.getStudentData()
    },

    handleInputChange(key, value) {
      this[key] = value
      key !== "page" && this.resetPage()
      this.getStudentData()
    },

    resetPage() {
      this.page = 1
    },

    handlePagination(newPage) {
      this.page = newPage
      this.getStudentData()
    },
  },
}
</script>

<style lang="scss" scoped>
.email {
  word-break: break-word;
}
</style>
