<template>
  <div
    class="bg"
    style="display: flex; justify-content: space-between; padding-block: 6px; margin-bottom: 12px;"
  >
    <v-btn
      class="pl-0"
      color="primary"
      :disabled="!(page - 1 >= 1)"
      text
      @click="prev"
    >
      <v-icon>mdi-menu-left</v-icon>
      <p class="text-body-1">Previous Page</p>
    </v-btn>
    <v-btn
      class="pr-0"
      color="primary"
      :disabled="!(page + 1 <= numberOfPages)"
      text
      @click="next"
    >
      <p class="text-body-1">Next Page</p>
      <v-icon>mdi-menu-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Paginator",

  props: {
    total: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },

  computed: {
    numberOfPages: function() {
      return Math.ceil(this.total / 10)
    },
  },

  methods: {
    next() {
      if (this.page + 1 <= this.numberOfPages) {
        this.$emit("change", this.page + 1)
      }
    },

    prev() {
      if (this.page - 1 >= 1) {
        this.$emit("change", this.page - 1)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
