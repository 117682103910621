<template>
  <v-btn class="pl-0 pr-1 mb-4" color="primary" small text @click="goBack()">
    <v-icon small>mdi-chevron-left</v-icon> BACK
  </v-btn>
</template>

<script>
export default {
  name: "GoBack",

  props: {
    route: {
      type: Object,
      default: null,
    },
  },

  methods: {
    goBack() {
      window.history.length > 1
        ? this.route
          ? this.$router.push(this.route)
          : this.$router.go(-1)
        : this.$router.push("/")
    },
  },
}
</script>

<style lang="scss" scoped></style>
